import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextCustom = makeShortcode("TextCustom");
const Img = makeShortcode("Img");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "encourage-independence"
    }}>{`Encourage independence`}</h1>
    <Grid container mdxType="Grid">
  <Grid item xs={12} sm={8} mdxType="Grid">
    <div>
      <p>
        Research shows that being involved in your teenager's life helps protect them from depression and clinical
        anxiety. However, being over-involved can increase their risk of developing depression and clinical anxiety
        <sup>1</sup>.
      </p>
      <p>
        You can avoid becoming over-involved in your teenager's life by promoting their autonomy or independence. You
        can do this by:
      </p>
      <TextCustom customColor="green" paragraph variant="h6" mdxType="TextCustom">
        Encouraging them to take on more responsibilities around the house.
      </TextCustom>
      <TextCustom customColor="purple" paragraph variant="h6" mdxType="TextCustom">
        Allowing them to do more things on their own over time.
      </TextCustom>
      <TextCustom customColor="red" paragraph variant="h6" mdxType="TextCustom">
        Checking that your teenager is not too dependent on you.
      </TextCustom>
    </div>
  </Grid>
  <Grid item xs={12} sm={4} mdxType="Grid">
    <Img src="/images/m4/9.svg" alt="flying bird" mdxType="Img" />
  </Grid>
  <Grid item xs={12} sm={8} mdxType="Grid">
    <Img src="/images/m4/7.svg" alt="tree" mdxType="Img" />
  </Grid>
    </Grid>
    <sup>
  1. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      